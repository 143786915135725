import { jwtDecode } from "jwt-decode"

export default defineNuxtRouteMiddleware(async () => {
  const token = useCookie("sam_token")
  if (!token.value) {
    return abortNavigation()
  }

  const decodedToken = jwtDecode<TokenBody>(token.value)

  if (!decodedToken.isAdmin) {
    return abortNavigation()
  }
})
